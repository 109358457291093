import { StudioDonationMatchingPlanDto } from 'models/studio'
import {
  DonationMatchingPlanLinkable,
  FeedItemTransaction,
  FeedItemWithContext,
} from './feed-item.model'
import { formatCurrency } from 'utils/formatCurrency'
import { logger } from 'utils/logger'

export const formatFeedItem = (
  feedItem: FeedItemWithContext,
  donationMatchingPlans?: StudioDonationMatchingPlanDto[],
) => {
  let formattedFeedItem = ''
  switch (feedItem.linkable_type) {
    case 'donation':
    case 'offline_donation':
    case 'offline_donation_doublethedonation':
      const transactionLinkable = feedItem.linkable as FeedItemTransaction

      const formatDonationAmount = (transactionLinkable: FeedItemTransaction) =>
        transactionLinkable.raw_currency_code && transactionLinkable.raw_initial_gross_amount
          ? formatCurrency(
              transactionLinkable.raw_currency_code,
              transactionLinkable.raw_initial_gross_amount,
            )
          : ''

      // donation match feed items
      if (feedItem.originalMatchedTransaction && feedItem.agent_type === 'business') {
        const isLastMatchOfSponsorPledge =
          (transactionLinkable?.raw_initial_gross_amount ?? 0) <
          (feedItem.originalMatchedTransaction?.raw_initial_gross_amount ?? 0)

        const isMatchedWithMultiplier =
          (transactionLinkable?.raw_initial_gross_amount ?? 0) >
          (feedItem.originalMatchedTransaction?.raw_initial_gross_amount ?? 0)

        const donationMatchPhrase = isLastMatchOfSponsorPledge ? `matched` : 'generously matched'

        const originalDonorName = feedItem.originalMatchedTransaction.is_anonymous
          ? ''
          : (feedItem.originalMatchedTransaction?.member_name?.trim() ?? '')

        const possessiveOriginalDonorName = originalDonorName.endsWith('s')
          ? `${originalDonorName}'`
          : `${originalDonorName}'s`

        const donorNamePhrase = originalDonorName
          ? `${possessiveOriginalDonorName}`
          : 'an anonymous'

        const amountPhrase = !feedItem.originalMatchedTransaction.hide_amount
          ? `${formatDonationAmount(transactionLinkable)}`
          : ''

        const multiplierPhrase = !feedItem.originalMatchedTransaction.hide_amount
          ? `, contributing ${formatDonationAmount(transactionLinkable)}`
          : ''

        if (isLastMatchOfSponsorPledge) {
          const studioDonationMatchingPlan = donationMatchingPlans?.find(
            (donationMatchingPlan) =>
              donationMatchingPlan.id === transactionLinkable.donation_matching_plan_id,
          )

          if (!studioDonationMatchingPlan) {
            logger(
              'error',
              new Error('Unable to find donation matching plan for the activity feed item'),
              {
                context: {
                  feedItemId: feedItem.id,
                  donationMatchingPlanId: transactionLinkable.donation_matching_plan_id,
                },
              },
            )
            break
          }

          formattedFeedItem = `${feedItem.agent_name} ${donationMatchPhrase} ${amountPhrase} ${feedItem.originalMatchedTransaction.is_anonymous ? '' : 'of'} ${donorNamePhrase} donation, fulfilling their ${formatCurrency(
            studioDonationMatchingPlan.currency_code,
            studioDonationMatchingPlan.matching_limit_amount,
          )} match pledge.`
          break
        }

        if (isMatchedWithMultiplier) {
          formattedFeedItem = `${feedItem.agent_name} ${donationMatchPhrase} ${donorNamePhrase} donation ${multiplierPhrase}.`
          break
        }

        formattedFeedItem = `${feedItem.agent_name} ${donationMatchPhrase} ${donorNamePhrase} ${amountPhrase} donation.`
        break
      }

      // regular donation feed items
      const amountPhrase = transactionLinkable.hide_amount
        ? ''
        : formatDonationAmount(transactionLinkable)
      const feedablePhrase = `to ${feedItem.feedable_value}`
      const feesPhrase = transactionLinkable.is_donor_covered_fee
        ? 'and generously covered the fees'
        : ''

      formattedFeedItem = `Donated ${amountPhrase} ${feedablePhrase} ${feesPhrase}.`
      break

    // creation of a donation matching plan
    case 'donation_matching_plan':
      const donationMatchingPlanLinkable = feedItem.linkable as DonationMatchingPlanLinkable
      const { id: donationMatchingPlanId } = donationMatchingPlanLinkable

      const studioDonationMatchingPlan = donationMatchingPlans?.find(
        (donationMatchingPlan) => donationMatchingPlan.id === donationMatchingPlanId,
      )

      if (!studioDonationMatchingPlan) {
        logger(
          'error',
          new Error('Unable to find donation matching plan for the activity feed item'),
          {
            context: {
              feedItemId: feedItem.id,
              donationMatchingPlanId,
            },
          },
        )
        break
      }

      const {
        currency_code,
        started_at,
        ended_at,
        matching_limit_amount,
        minimum_donation_amount,
        matching_multiplier,
      } = studioDonationMatchingPlan

      const pledgePhrase =
        new Date(started_at).getTime() > Date.now() ? 'pledged to match' : 'is matching'

      const matchingLimitPhrase = matching_limit_amount
        ? `up to ${formatCurrency(currency_code, matching_limit_amount)} in`
        : ''

      const dateTimeFormatOptions = {
        year: 'numeric' as const,
        month: 'long' as const,
        day: 'numeric' as const,
        hour: 'numeric' as const,
        minute: 'numeric' as const,
        timeZoneName: 'short' as const,
      }

      const formatDate = (date: string) =>
        Intl.DateTimeFormat('en-US', dateTimeFormatOptions)
          .format(new Date(date))
          .replace(':00', '')

      const timePhrase = () => {
        // start and end date in the future
        if (new Date(started_at).getTime() > Date.now() && ended_at) {
          return `between ${formatDate(started_at)} and ${formatDate(ended_at)}`
        }

        // start date in the future and no end date
        if (new Date(started_at).getTime() > Date.now() && !ended_at) {
          return `starting ${formatDate(started_at)}`
        }

        // start date in the past and end date in the future
        if (ended_at) {
          return `until ${formatDate(ended_at)}`
        }

        // no relevant time information to display
        return ''
      }

      const minimumDonationPhrase =
        minimum_donation_amount > 1
          ? `and donations over ${formatCurrency(currency_code, minimum_donation_amount)}`
          : ''

      const matchingMultiplierPhrase = matching_multiplier > 1 ? ` by ${matching_multiplier}x` : ''

      formattedFeedItem = `${feedItem.agent_name} ${pledgePhrase} ${matchingLimitPhrase} donations ${minimumDonationPhrase} ${matchingMultiplierPhrase} ${timePhrase()}.`
      break

    default:
      break
  }

  // replace all extra spaces with a single space, and remove any spaces before a period or comma
  formattedFeedItem = formattedFeedItem.replaceAll(/\s{2,}/g, ' ').replace(/\s+([\.,])/, '$1')

  if (feedItem.linkable && 'comment' in feedItem.linkable && feedItem.linkable.comment) {
    formattedFeedItem += `<blockquote>${feedItem.linkable.comment}</blockquote>`
  }

  return formattedFeedItem
}
