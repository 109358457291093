/**
 * Format an integer/string into a dollar amount based on given currencyCode
 */
export const formatCurrency = (currencyCode: string, amount: string | number, locale?: string) => {
  /**
   * Get the user's locale (e.g. en-US, en-GB, etc.)
   * This is retrieved from the browser's settings.
   */
  const userLocale = locale || Intl.DateTimeFormat().resolvedOptions().locale

  // * Read more about Int.NumberFormat on MDN
  // * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
  const formatAmount = new Intl.NumberFormat(userLocale, {
    style: 'currency', // Set the style of formatting to 'currency'.
    currency: currencyCode, // Set the currency code for formatting.
    currencyDisplay: 'symbol',
  }).format(Number(amount)) // Format the provided 'amount' as a number and apply currency formatting.

  return formatAmount
}
