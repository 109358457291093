import React from 'react'
import { ActivityFeed, ActivityFeedProps, Member } from '@classy/campaign-page-blocks'
import { useWhammyDispatch } from './useWhammyDispatch'
import { useActivityFeedReducer } from './useFeedItemsReducer'
import { logger } from 'utils/logger'
import { StudioDonationMatchingPlanDto } from 'models/studio'
import { onLogin } from 'services/sso'
import { whammyFetch } from 'classy-api-proxies/whammy'
import { FetchMemberPermissionsResponse, hasAllPermissions } from 'services/cerberus'

interface ActivityFeedControllerProps extends ActivityFeedProps {
  member?: Member
}

/**
 * ActivityFeed page block wrapper.
 *
 * See https://bitbucket.org/stayclassy/campaign-page-blocks/pull-requests/657 for a useful
 * overview diagram.
 */
export const ActivityFeedController = ({ ...rest }: ActivityFeedControllerProps) => {
  const { campaignId, donationMatchingPlans, member, orgId } = rest
  const [, dispatch] = useActivityFeedReducer()
  const [loggedInMember, setLoggedInMember] = React.useState<Member>()

  if (!campaignId) {
    throw new Error('Activity feed requires a campaigin id.')
  }

  const [isLoading, setIsLoading] = React.useState(true)
  const { feedItemsState, whammyDispatch } = useWhammyDispatch(
    campaignId,
    // donationMatchingPlans is an external data prop, but it is only needed/used in Whammy.
    // CPB types it as unknown, so we narrow the type down to the relevant APIv2 model here as
    // we don't want to store APIv2 models in CPB.
    donationMatchingPlans as StudioDonationMatchingPlanDto[],
  )

  React.useEffect(() => {
    // Fetch initial user comments and autogenerated feed items
    ;(async () => {
      if (!isLoading) return

      try {
        await whammyDispatch({ type: 'GET_INITIAL_FEED_ITEMS' })
      } catch (e) {
        logger(
          'error',
          new Error("Unable to load the activity feed's initial items", { cause: e }),
          {
            context: {
              campaignId,
            },
          },
        )
        // Per product/design, if there is an API error, only show the empty state
        dispatch({
          type: 'SET_INITIAL_FEED_ITEMS_STATE',
          state: {
            items: [],
            currentPage: 1,
            total: 0,
          },
        })
      } finally {
        setIsLoading(false)
      }
    })()
  }, [campaignId, dispatch, isLoading, whammyDispatch])

  React.useEffect(() => {
    ;(async () => {
      if (!member) {
        // Don't check delete permissions, member is not logged in
        return
      }

      try {
        const memberPermissionsResultRaw = await whammyFetch(`/actors/${member.id}/auth`, {
          method: 'POST',
          body: JSON.stringify({
            permissions: [
              { entity: 'feed-item', action: 'delete' },
              { entity: 'comment', action: 'delete' },
            ],
            scopes: { organization: [orgId], campaign: [campaignId] },
          }),
        })
        const results: FetchMemberPermissionsResponse = await memberPermissionsResultRaw.json()

        setLoggedInMember({
          ...member,
          canDeleteComments: hasAllPermissions(results),
        })
      } catch (e) {
        logger(
          'error',
          new Error('Unable to determine delete permissions for the logged-in member', {
            cause: e,
          }),
          {
            context: {
              campaignId,
              orgId,
              memberId: member.id,
            },
          },
        )
      }
    })()
  }, [campaignId, member, orgId])

  return (
    <ActivityFeed
      {...rest}
      isLoading={isLoading}
      feedItems={feedItemsState.items}
      totalFeedItems={feedItemsState.total}
      currentPage={feedItemsState.currentPage}
      whammyDispatch={whammyDispatch}
      loggedInMember={loggedInMember}
      onLogin={() => onLogin(orgId)}
    />
  )
}
